import { ProductFormType } from "../../../formValidation";
import { useFormContext } from "react-hook-form";
import { FC } from "react";
import { useLookups } from "../../../../lookups/api/useLookups";
import { PriceTypeDto } from "../../../../../types/contracts/contracts";
import { useSelectedLocation } from "../../../../locations/hooks/useSelectedLocation";


type PriceTypeProps = {
  index: number;
  isDisabled: boolean;
};

export const locationsEnabledForTakeawayPricing = [
  368485,     // QA: Puzzles
  388595,     // QA: Wizarding world
  191127618,  // Prod: BIT Bussterminalen
  212485270,  // Prod: BIT Oslo Lufthavn
  203386383,  // Prod: Cyclo spisebar
  27340020,   // Prod: Eirik's demo location
  27271168,   // Prod: Miriam's demo location
  90503094,   // Prod: Even's demo location
];

const PriceType: FC<PriceTypeProps> = ({ index, isDisabled }) => {
  const { register, setValue, getValues, formState: { errors } } = useFormContext<ProductFormType>();
  const { data: lookups } = useLookups();
  const { selectedLocation } = useSelectedLocation();

  let priceTypes;
  if (lookups !== undefined) {
    if (selectedLocation !== undefined && locationsEnabledForTakeawayPricing.includes(selectedLocation.locationId)) {
      // Render all choices
      priceTypes = lookups.priceTypes;
    } else {
      // Just render PER_ITEM and OPEN_PRICE
      priceTypes = (lookups.priceTypes.filter((item) => item.priceTypeCategoryId == "GENERAL"))
    }
    if (!getValues(`sizes.${index}.priceType`)) {
      setValue(`sizes.${index}.priceType`, priceTypes[0].priceTypeId);
    }
  }
  const fieldError= errors.sizes?.[index]?.priceType

  return <div className={"flex"}>
      <label>
        <div className="pb-2 umami-form-label">{"Price type"}</div>
        <select
          {...register(`sizes.${index}.priceType`)}
          className={`text-sm border rounded py-[14px] px-[12px] ${fieldError ? "border-orange-darkest" : "border-grey-medium"}`}
          disabled={isDisabled}
        >
          {priceTypes?.map((priceType: PriceTypeDto) => (
            <option key={priceType.priceTypeId} value={priceType.priceTypeId}>{priceType.priceTypeName}</option>
          ))}
        </select>
      </label>
      {fieldError && <p className="text-sm text-orange-darkest">{fieldError.message}</p>}
  </div>;
};

export default PriceType;
